/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { navigate } from 'gatsby';
import axios from 'axios';
import { Layout } from 'components';
import './register-demand.scss';

const RegistrationForm = () => {
  const [submitted, setSubmitted] = useState(false);
  const [clickedSubmit, setClickedSubmit] = useState(false);

  useEffect(() => {
    if (submitted) {
      navigate('/thankyou');
    }
  }, [submitted]);

  // form functions

  const initialValues = {
    email: '',
    name: '',
    company: '',
    phone: '',
    products: [],
    mask: '',
    format: '',
    amount: '',
  };

  const fields = [
    { type: 'text', name: 'name', placeholder: 'Enter your name', label: 'What is your name?' },
    { type: 'email', name: 'email', placeholder: 'Enter your email', label: 'What is your email address?' },
    { type: 'text', name: 'phone', placeholder: 'Enter your phone number', label: 'What is your best contact number?' },
    { type: 'text', name: 'company', placeholder: 'Enter your Company', label: 'What organisation do you belong to?' },
    {
      type: 'checkbox',
      name: 'products',
      label: 'What do you need?',
      options: ['Hand sanitiser', 'Masks'],
    },
    {
      type: 'radio',
      name: 'mask',
      showIf: { field: 'products', value: 'Masks' },
      label: 'What type of mask?',
      options: ['N95', 'Surgical'],
    },
    {
      type: 'radio',
      name: 'format',
      showIf: { field: 'products', value: 'Hand sanitiser' },
      label: 'What hand sanitiser do you need?',
      options: ['60mL', '500mL', '1L'],
    },
    {
      type: 'radio',
      name: 'amount',
      label: 'How much do you need?',
      options: ['10,000', '50,000', '100,000', '150,000+'],
    },
  ];

  const validation = values => {
    const errors = {};

    if (!values.name) {
      errors.name = 'Please provide a first name';
    } else if (!values.email) {
      errors.email = 'Please provide an email address';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Invalid Email address';
    } else if (!values.phone) {
      errors.phone = 'Please provide a phone number';
    } else if (!values.company) {
      errors.company = 'Please provide your company name';
    } else if (!values.products || values.products.length < 1) {
      errors.products = 'Please select a product type';
    } else if (!values.mask && values.products.indexOf('Masks') !== -1) {
      errors.mask = 'Please select a mask type';
    } else if (!values.format && values.products.indexOf('Hand sanitiser') !== -1) {
      errors.format = 'Please select a format';
    } else if (!values.amount) {
      errors.amount = 'Please select an amount';
    }
    return errors;
  };

  // Encode values to query string
  const encode = data =>
    Object.keys(data)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
      .join('&');

  const onSubmit = async (values, { setSubmitting }) => {
    await axios.post(
      'https://a5w3o6c2j1.execute-api.ap-southeast-2.amazonaws.com/dev?format=json',
      encode({
        ...values,
        _to: '9f9e21b187a631167fde15df6fbf9c2033572a141f1b0a',
        _sender: 'Rescue',
        _formname: 'Register Demand',
        _replyTo: values.email,
      }),
      {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      }
    );
    setSubmitting(false);
    setSubmitted(true);
  };

  return (
    <Layout>
      <section className="form-container">
        <div className="wrapper form-wrapper">
          <h1 className="form-container-title">Register Demand</h1>
          <Formik initialValues={initialValues} validate={validation} onSubmit={onSubmit}>
            {({ isSubmitting, errors, handleChange, values }) => {
              return (
                <Form>
                  {fields.map(field => {
                    const hasError = errors[field.name] && clickedSubmit;

                    if (field.showIf && values[field.showIf.field].indexOf(field.showIf.value) === -1) return null;

                    return (
                      <div className="field">
                        <p>{field.label}</p>
                        <FormikField {...field} handleChange={handleChange} />
                        <div className={`validation-error ${hasError ? 'active' : 'inactive'}`}>
                          {hasError && <ErrorMessage name={field.name} component="div" />}
                        </div>
                      </div>
                    );
                  })}
                  <button
                    type="submit"
                    className="button"
                    disabled={isSubmitting}
                    onClick={() => setClickedSubmit(true)}
                  >
                    Register Demand
                  </button>
                </Form>
              );
            }}
          </Formik>
        </div>
      </section>
    </Layout>
  );
};

const FormikField = ({ name, options, type, label, placeholder, index, handleChange }) => {
  if (type === 'select') {
    return (
      <>
        <Field as="select" name={name}>
          {options.map(option => (
            <option value={option} key={option}>
              {option}
            </option>
          ))}
        </Field>
      </>
    );
  }

  if (type === 'radio' || type === 'checkbox') {
    return (
      <div className="radio-buttons">
        {options.map(option => (
          <Field
            name={name}
            render={() => (
              <div key={option} className="radio-button">
                <input type={type} id={option} name={name} value={option} onChange={handleChange} />
                <label htmlFor={option}>{option}</label>
              </div>
            )}
          />
        ))}
      </div>
    );
  }

  return <Field type={type} name={name} id={name} placeholder={placeholder} autoFocus={index === 0} />;
};

export default RegistrationForm;
